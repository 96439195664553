import { Link } from 'gatsby';
import { MdSearch } from 'react-icons/md';
import PostFeaturedImage from './PostFeaturedImage';
import PostMeta from './PostMeta';
import PostsGrid from './PostsGrid';
import SocialShare from './SocialShare';
import { PostCategoryProps, PostEntryProps } from '../types';

interface Props {
  post: PostEntryProps;
  relatedPosts?: {
    nodes?: PostEntryProps[];
  };
}

export default function Post({ post, relatedPosts }: Props) {
  if (!post) {
    return null;
  }

  const isStory = post.contentType?.node?.name === 'story';

  const categories = isStory
    ? ([{ slug: 'videos', name: 'Videos' }] as PostCategoryProps[])
    : post.categories?.nodes;
  const category = categories?.length ? categories[0] : null;
  const archiveUrl = isStory ? '/videos' : `/news-press/${category?.slug}`;

  return (
    <div className="single-post">
      <section className="container section-m-t section-m-b">
        <div className="row">
          <main className="col-xs-12 col-sm-10 col-md-7 col-lg-6 col-lg-offset-1">
            <nav className="breadcrumbs content-m-b">
              <ul className="breadcrumbs__inner">
                <li className="breadcrumbs__item">
                  <Link className="breadcrumbs__item__inner" to={archiveUrl}>
                    {category?.name || 'News'}
                  </Link>
                </li>
                <li className="breadcrumbs__item">
                  <span
                    className="breadcrumbs__item__inner"
                    aria-current="page"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: post.title }}
                  />
                </li>
              </ul>
            </nav>
            <div className="post-header">
              <h1 className="h2">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: post.title }}
                />
              </h1>
              <div className="post-header__byline">
                <div className="post-header__byline__inner">
                  <div className="post-header__byline__meta">
                    <PostMeta
                      date={post.date}
                      categories={categories}
                      isStory={isStory}
                    />
                  </div>
                  <div className="post-header__byline__social">
                    <SocialShare title={post.title} absoluteUrl={post.uri} />
                  </div>
                </div>
              </div>
              <PostFeaturedImage
                post={post}
                className="post-entry__img  post-header__img"
                embed
              />
            </div>
            {post.content && (
              <div className="post-content">
                <div
                  className="text-block"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
              </div>
            )}
            <div className="post-footer">
              <div className="post-footer__social">
                <SocialShare title={post.title} absoluteUrl={post.uri} />
              </div>
            </div>
          </main>
          <aside className="col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-3 col-lg-offset-1">
            <div className="sidebar">
              <div className="sidebar-section sidebar-search">
                <form
                  className="searchform"
                  action={isStory ? `/videos` : '/news-press'}
                >
                  <input
                    type="search"
                    placeholder={`Search ${
                      isStory ? 'Videos' : 'News / Press'
                    }`}
                    name="search"
                  />
                  <button type="submit" aria-label="Search">
                    <MdSearch />
                  </button>
                </form>
              </div>
              {relatedPosts?.nodes && (
                <div className="sidebar-section sidebar-posts">
                  <h2 className="h3">
                    Related {isStory ? 'Videos' : 'Articles'}
                  </h2>
                  <PostsGrid posts={relatedPosts.nodes} image meta />
                  <div className="sidebar-posts__more align-right">
                    <Link to={archiveUrl} className="button">
                      See All
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </aside>
        </div>
      </section>
    </div>
  );
}
