import { graphql, PageProps } from 'gatsby';
import Post from '../components/Post';
import SEO from '../components/Seo';
import { PostEntryProps } from '../types';

interface DataProps {
  post: PostEntryProps;
  relatedPosts?: {
    nodes?: PostEntryProps[];
  };
}

export function Head({
  location,
  data,
}: {
  location: { pathname: string };
  data: DataProps;
}) {
  const { post } = data;

  return (
    <SEO
      title={post.title}
      description={post.excerpt}
      pathname={location.pathname}
    />
  );
}

export default function PostTemplate({ data }: PageProps<DataProps>) {
  const { post, relatedPosts } = data;

  if (!post) {
    return null;
  }

  return <Post post={post} relatedPosts={relatedPosts} />;
}

export const pageQuery = graphql`
  query ($id: String!, $categories: [String]) {
    post: wpPost(id: { eq: $id }) {
      ...PostEntry
      content
    }
    relatedPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { in: $categories } } } }
        id: { nin: [$id] }
      }
      limit: 3
    ) {
      nodes {
        ...PostEntry
      }
    }
  }
`;
